import React from "react"
import { graphql } from "gatsby"
import CallToAction from "../../widgets/CallToAction/CallToAction"
import { IParagraph } from "../../components/Article/types"
import styled from "styled-components"
import {
  SecondarySection,
  Head,
  Container,
  Article,
} from "../../components/components"

const article: IParagraph[] = [
  {
    subtitle: "Chancen",
    heading: "Agilität durch Prozessoptimierung",
    image: "process",
    paragraphs: [
      "In einem Zeitalter, in dem Nachrichten über neue Errungenschaften in Echtzeit auf verschiedensten Plattformen in die ganze Welt übertragen werden, verwundert es nicht, dass die Ansprüche und Anforderungen von Kunden und Märkten sich immer schneller weiterentwickeln. Diese wachsende Schnelllebigkeit bewirkt kürzere Produktlebenszyklen und lässt Innovationen nicht lange innovativ erscheinen. Um der Nachfrage gerecht werden zu können, stehen die Anbieter vor der Herausforderung, ihre Produkte und Dienstleistungen in immer kürzeren Zeiträumen zu entwerfen, umzugestalten und dem Abnehmer zur Verfügung zu stellen, bevor die Konkurrenz den Bedarf stillt.",
      "Um das Entwerfen neuer Produkte und Konzepte in einem beschleunigten Verfahren zu ermöglichen, gilt es eine Infrastruktur zu schaffen, die eben jene Flexibilität begünstigt. Dies kann in der Regel durch eine Optimierung standardisierter Prozesse umgesetzt werden. Prozesse, die sich in ihrer Art und ihren Anforderungen gleichen, stellen keine große Herausforderung dar, sind jedoch häufig zeit- und kostenintensiv. Um diese Zeit und Kosten zu sparen, können Abläufe häufig automatisiert oder zumindest beschleunigt bzw. vereinfacht werden.",
    ],
  },
  {
    subtitle: null,
    heading: "Potenziale erkennen",
    image: null,
    paragraphs: [
      "In einem Zeitalter, in dem Nachrichten über neue Errungenschaften in Echtzeit auf verschiedensten Plattformen in die ganze Welt übertragen werden, verwundert es nicht, dass die Ansprüche und Anforderungen von Kunden und Märkten sich immer schneller weiterentwickeln. Diese wachsende Schnelllebigkeit bewirkt kürzere Produktlebenszyklen und lässt Innovationen nicht lange innovativ erscheinen. Um der Nachfrage gerecht werden zu können, stehen die Anbieter vor der Herausforderung, ihre Produkte und Dienstleistungen in immer kürzeren Zeiträumen zu entwerfen, umzugestalten und dem Abnehmer zur Verfügung zu stellen, bevor die Konkurrenz den Bedarf stillt.",
      "Um die Ressourcen nicht hochfahren zu müssen, kann eine Vielzahl von Abläufen so vereinfacht und zusammengefasst werden, dass eine Steigerung der Effizienz bei gleichbleibenden Ressourcen nicht lange auf sich warten lässt. Wenn Informationen zum Beispiel von mehreren Verantwortlichen bearbeitet und zur Prüfung untereinander ausgetauscht werden müssen, wählen Cloud-basierte Lösungen den Ansatz, die Information simultan für alle Verantwortlichen zugänglich zu machen, wodurch auf mehreren Ebenen Ressourcen eingespart werden können. Simple, kostengünstige und vor allem schnelle Lösungen steigern die Effizienz, Geschwindigkeit, Flexibilität und nicht zuletzt die Zufriedenheit der Angestellten und bilden damit das Fundament für einen ganzheitlichen Erfolg. Jedes Unternehmen definiert den Erfolg mit eigenen Kennzahlen, doch ist es schwer vorzustellen, dass Zeit- und Kostenersparnis bei steigender Mitarbeitermotivation dabei keine relevanten Faktoren darstellen.",
    ],
  },
  {
    subtitle: null,
    heading: "Vermeintliche Risiken der Digitalisierung",
    image: null,
    paragraphs: [
      "Ohnehin sollte die Prozessoptimierung bestenfalls eine stetige Entwicklung darstellen, da sich durch technologischen Fortschritt, wachsende Kompetenzen oder wechselnde Bedürfnisse andere Voraussetzungen ergeben können. Doch gerade der Schritt der Digitalisierung von analogen Prozessen wird häufig unnötig hinausgezögert, da mit der Digitalisierung nicht selten auch Ängste einhergehen. In diesem Zusammenhang werden Risiken wie ein vermeintlicher Kontrollverlust oder hohe Anschaffungskosten angeführt, wobei grundsätzlich das Gegenteil der Fall ist. Digitalisierte Prozesse lassen sich so gestalten, dass ein ausgewählter Personenkreis sie kontrollieren oder bearbeiten kann. Jede verarbeitete Information kann auf diesem Wege vor unberechtigten Zugriffen geschützt oder einer zuvor definierten Gruppe zugänglich gemacht werden. Auf diesem Wege haben Sie jederzeit die Kontrolle darüber, wer wann was und sogar von wo aus einsehen oder bearbeiten darf. Informationen können zentralisiert zusammengetragen und daraus resultierend Daten erhoben werden, die indizieren, an welchen Ecken Handlungsbedarf besteht. Von Kontrollverlust kann somit keine Rede sein.",
      "Was die anfänglichen Anschaffungskosten angeht, sind diese natürlich nicht von der Hand zu weisen. Jedoch stehen diese in keinem Verhältnis zu den einzusparenden Ressourcen und amortisieren sich in der Regel innerhalb kürzester Zeit. Kosten für die Digitalisierung schlagen lediglich initial im Rahmen der Lösungsentwicklung zu Buche und sind als lohnende Investition in das langfristige Unternehmenswachstum zu betrachten. Ihre Ressourcen sind in der Optimierung essentieller Prozessabläufe und der Erweiterung Ihrer geschäftlichen Möglichkeiten besser aufgehoben als darin ausgebildetes Fachpersonal für das Ausfüllen und Weiterleiten von Formularen zu bezahlen.",
    ],
  },
  {
    subtitle: null,
    heading: "Das Werkzeug unserer Zeit",
    image: null,
    paragraphs: [
      "Im Zeitalter der digitalen Revolution ist die passende Software das Werkzeug unserer Zeit. Wie einst das Rad zuvor Unmögliches möglich und Schweres einfach gemacht hat, sind es heute digitale Lösungen, die den Stein ins Rollen bringen. Mit individueller Software, die auf Ihre Bedürfnisse zurechtgeschnitten ist, sich Ihren Geschäftsprozessen anpasst und sich nahtlos eingliedert, helfen wir Ihnen Ihr Potenzial auszuschöpfen. Von der Digitalisierung analoger Prozesse über die Automatisierung manuell ausgeführter Abläufe bis hin zur Vereinfachung und Beschleunigung dieser stehen wir Ihnen mit unserer Expertise und Erfahrung als zuverlässiger Partner beiseite. Schreiben oder rufen Sie uns einfach an, um einen Termin zu vereinbaren.",
    ],
  },
]

const ProcessOptimization = ({ data }: { data: any }) => {
  return (
    <>
      <Head pagePath="/chancen/prozessoptimierung" />
      <SecondarySection withArticle>
        <Container style={{ alignItems: "center", justifyContent: "center" }}>
          <BlogContainer>
            <Article article={article} image={data.process.image} />
          </BlogContainer>
        </Container>
      </SecondarySection>
      <CallToAction type="customer" />
    </>
  )
}

export default ProcessOptimization

export const query = graphql`
  query {
    process: file(relativePath: { eq: "stock/process.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 1920, layout: FULL_WIDTH)
      }
    }
  }
`
const BlogContainer = styled(Container)`
  max-width: 860px !important;
`
